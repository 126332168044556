.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading .ant-spin {
  color: #20A0B0;
}
.text-info {
    color: #20A0B0 !important;
}
.square {
    height: 20px;
    width: 20px;
}
.popular {
    background-color: #20A0B0;
    color: white;
    padding: 0 5px;
    font-size: 10px;
    font-weight: bolder;
}
.container-vigencia {
    border: 3px dashed #ededed;
    border-radius: 1rem;
}
.main-container-logo-upload {
    display: flex;
    flex-direction: row;
}
.container-img-logo {
    height: 98px;
    width: 98px;
    padding: 5px;
    margin-right: 10px;
    box-sizing: content-box;
    border: 1px solid lightgray;
    border-radius: 8px;
}

.circle-button {
    background-color: #233442;
    color: white;
}
.margin-top{
    margin-top: 1rem;
}